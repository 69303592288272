import styled from 'styled-components'
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system'
 
// Add styled-system functions to your component
const Box = styled.div`
  ${layout}
  ${space}
  ${color}
  ${background}
  ${layout}
  ${border}
  ${flexbox}
  ${typography}
  ${position}
  ${shadow} {
    disabled: ${props => props.disabled};
    cursor: ${props => props.cursor};
    ${props => props.css};
  }
`

export default Box