import {  useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchBookCategories } from 'store/actions/bookActions'

import { Button, Wrapper } from "./BookCategories.styled";

const BookCategories = () => {
  const dispatch = useDispatch()
  const categories = useSelector(gs => gs.books.categories)

  useEffect(() => {
     dispatch(fetchBookCategories())
  }, [])

  if(!categories?.length) {
    return null
  }
  
  return (
    <Wrapper>
    {categories?.map(item => <Button key={item.id}>{item.name}</Button>)}
    </Wrapper>
  )
}

export default BookCategories
