import produce from 'immer'

export const ACTION_TYPES = {
  SET_LOADING: 'APP/SET_LOADING',
  SET_USER_AUTHENTICATED: 'APP/SET_USER_AUTHENTICATED',
  SET_ERROR_MESSAGE: 'APP/SET_ERROR_MESSAGE',
  SET_REDIRECT_TO_LOGIN: 'APP/SET_REDIRECT_TO_LOGIN',
}

export const initialState = {
  isLoading: false,
  errorMessage: null,
  redirectToLogin: false,
}

const globalReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOADING:
      draft.isLoading = action.payload
      break
    case ACTION_TYPES.SET_ERROR_MESSAGE:
      draft.errorMessage = action.payload
      break
    case ACTION_TYPES.SET_REDIRECT_TO_LOGIN:
      draft.redirectToLogin = action.payload
      draft.errorMessage = ''
      break
    default:
      break
  }
})

export default globalReducer
