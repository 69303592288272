import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0 auto;
  min-width: 600px;
  max-width: 1700px;
`;

export const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
  margin: 0 10px;
  gap: 10px;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
