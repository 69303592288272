import React from 'react'
import { PropagateLoader } from 'react-spinners'

import { InternalWrapper, Wrapper } from './Loading.styled'

export const Loading = React.memo((props) => {
  const Component = props.internal ? InternalWrapper : Wrapper

  return (
    <Component>
      <PropagateLoader size={20} color='#4d9aff' />
    </Component>
  )
})

Loading.displayName = 'Loading'

export default Loading
