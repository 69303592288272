import styled from "styled-components";

export const Button = styled.button`
  margin: 0 10px;
  color: -internal-light-dark(black, white);
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  height: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  margin: 20px auto 0;
  align-items: center;
  justify-content: center;
`;
