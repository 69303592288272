import styled from 'styled-components'

import Box from 'components/Box'

export const NotFoundContainer = styled.div`
  padding: 16px;
  max-width: 90%;
  border: 1px solid #dc0808;
  margin: 20% auto;
  text-align: center;
`

export const Container = styled.div`
  display: flex;
  padding: 16px;
  width: 90%;
  border: 1px solid #e2e2e2;
  margin: 10% auto;
`

export const BookDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  border: 1px solid #eae9e9;
`


export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;

  button {
    margin: 0 8px;
  }
`

export const BookPageImage = styled.img`
  padding: 16px;
  width: 120px;
  height: 100%;
  margin: 0 16px;
`

export const Image = styled.img`
  width: 140px;
  height: 200px;
  box-sizing: border-box;
  margin-right: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
`;


export const BookContainer = ({ children }) => (
  <Box  
    display='flex'
    border={1}
    borderColor='softBlue'
    borderRadius={2}
    p={5}
    width='100%'
    maxWidth={800}
    mb={6}
  >
    {children}
  </Box>
)