import axios from 'axios'
import Constants from 'config/constants'

import getParamString from 'utils/getParamString'
import sortByName from 'utils/sortByName'

class BookService {
  constructor() {
    this.baseUrl = Constants.API_BASE_URL
  }

  getBooks = async (params) => {
    let query = getParamString(params)
    query = !!query ? `?${query}` : query
    return axios
      .get(`${this.baseUrl}/books${query}`)
      .then((payload) => payload.data)
  }

  getBookDetail = async (id) => {
    return axios
      .get(`${this.baseUrl}/books/${id}`)
      .then((payload) => payload.data)
  }
  
  getBookCategories = async () => {
    return axios
      .get(`${this.baseUrl}/categories`)
      .then(payload => {
        return payload.data
        .map(item => ({  
          id: item._id,
          name: item.categoryName,
        }))
        .sort(sortByName)
      })
  }
}

export default new BookService()
