import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'

import userService from 'services/userService'
import { fetchBooks } from 'store/actions/bookActions'

import Box from 'components/Box'

import {
  AccountItem,
  AccountWrapper,
  Header,
  Logout,
  Title,
} from './NavBar.styled'

const NavBar = () => {
  const dispatch = useDispatch()
  const history  = useHistory()
  const accountState = useSelector(gs => gs.account)
  const { pathname } = useLocation()

  const handleLogout = ()  => {
    userService.logout()
  }

  const handleHomePageClick = () => {
    if(window.location.pathname === '/') {
      window.history.pushState({ }, '', '/')
      return dispatch(fetchBooks({}))
    }
    history.push('/')
  }

  if(pathname.startsWith('/himmet')) {
    return null
  }

  return (
    <Header>
      <Title>
        <Box as='a' cursor='pointer' onClick={handleHomePageClick}>
          HSource
        </Box>
      </Title>
      {accountState.isAuthenticated &&
      <AccountWrapper>
        <AccountItem>
          <Link to='/user'>
            {accountState.username}
            </Link>  
        </AccountItem>
        <AccountItem>
          <Logout onClick={handleLogout}>
            Cikis
          </Logout>
        </AccountItem>
        </AccountWrapper>
      }
      {!accountState.isAuthenticated &&
        <AccountWrapper>
          <AccountItem>
            <Link to='/signin'>
              Uye Giris
            </Link>  
          </AccountItem>
          <AccountItem>
            <Link to='/signup'>
              Kaydol
            </Link>
          </AccountItem>
        </AccountWrapper>
      }
    </Header>
  )
}

export default NavBar