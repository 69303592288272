import { useSelector } from 'react-redux'

import BookItem from "components/BookItem"
import Box from 'components/Box'
import LoadMoreBooks from 'components/LoadMoreBooks/LoadMoreBooks'

import { Wrapper, Grid } from "./BookList.styled"

const BookList = () => {
  const { books, totalCount } = useSelector(gs => gs.books)
  const user = useSelector(gs => gs.account)
  
  if(!books?.length) {
    return null
  }

  const isInReadingList = (bookId) => {
    if(!user?.bookList?.length) {
      return false
    }
    return user.bookList.some(book => book.bookDetail.bookId === bookId)
  }

  return (
    <Wrapper>
      {totalCount > 0 &&
        <Box textAlign='center' mb={20}>{`${totalCount} kitap bulundu`}</Box>
      }
      <Grid>
        {books.map((book, index) => (
          <BookItem
            {...book}
            key={index}
            addToList={user.isAuthenticated && !isInReadingList(book.bookId)}
            removeFromList={user.isAuthenticated && isInReadingList(book.bookId)}
          />
        ))}
      </Grid>
      <LoadMoreBooks />
    </Wrapper>
  )
}
export default BookList