import React from 'react';

import { Select } from './ForgettenPasswordQuestions.styled'

const ForgettenPasswordQuestions = (props) => {
  return (
    <Select id={props.id} onChange={props.onChange}>
      <option value=''>Seciniz</option>
      <option>En sevdiginiz renk</option>
      <option>Ilk ogretmeninizin adi</option>
    </Select>
  );
}

export default ForgettenPasswordQuestions;
