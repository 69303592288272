import { ACTION_TYPES } from 'store/reducers/globalReducer'

export const setLoading = payload => dispatch => {
  dispatch({
    type: ACTION_TYPES.SET_LOADING,
    payload,
  })
}

export const setErrorMessage = payload => dispatch => {
  dispatch({
    type: ACTION_TYPES.SET_ERROR_MESSAGE,
    payload,
  })
}

