import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'

import App from 'pages/App'
import store from 'store/index'
import theme from './theme'

import setupAxiosInterceptors from 'utils/axiosInterceptors'

import reportWebVitals from './reportWebVitals'

import 'bootstrap/dist/css/bootstrap.css'
import './index.css'

setupAxiosInterceptors()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
