import { useState,useRef } from "react";
import BookCategories from "components/BookCategories";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  Input,
  Wrapper,
  Icon,
  WrapperIcon,
  Keyboard,
  KeyboardKey,
} from "./Search.styled";
import "react-simple-keyboard/build/css/index.css";

const Search = (props) => {
  const [lookState, setLookState] = useState(0);
  const inputRef = useRef();
  const layoutTR = [
    ["caps", "CAPS"],
    ["ç", "Ç"],
    ["ğ", "Ğ"],
    ["ı", "İ"],
    ["ö", "Ö"],
    ["ş", "Ş"],
    ["ü", "Ü"],
  ];

  const handleKey = (e, key) => {
    e?.preventDefault();
    if (key === "caps" || key === "CAPS") {
      lookState ? setLookState(0) : setLookState(1);
    } else {
      props.onChange({ target: { value: props.value.concat(key) } });
    }
    inputRef.current.focus();
  };

  const onChangeInput = (event) => {
    props.onChange({ target: { value: event.target.value } });
  };

  const handleOnSubmit = (e) => {
    e?.preventDefault();
    if (props.onSubmit()) {
      props.onSubmit();
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <Keyboard styledProp={lookState}>
        {layoutTR.map((key) => {
          return (
            <KeyboardKey
              type="button"
              key={key[0]}
              onClick={(e) => handleKey(e, key[lookState])}
            >
              {key[lookState]}
            </KeyboardKey>
          );
        })}
      </Keyboard>

      <Wrapper>
        <Input
          id="inputSearch"
          ref={inputRef}
          onChange={onChangeInput}
          value={props.value || ""}
          placeholder="En az üç harf giriniz!"
          autoFocus
        />
        <WrapperIcon onClick={handleOnSubmit}>
          <Icon icon={faSearch} />
        </WrapperIcon>
      </Wrapper>
      <BookCategories />
    </Form>
  );
};
export default Search;
