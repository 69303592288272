import React, { useState } from 'react'
import to from 'await-to-js'
import trim from 'lodash/trim'

import userService from 'services/userService'

import ForgettenPasswordQuestions from 'components/ForgettenPasswordQuestions'
import Input from 'components/Input'

import {
  Button,
  ButtonWrapper,
  Container,
  Divider,
  ErrorMessage,
  Form,
  FormGroup,
  Label,
  SuccessMessage,
  Title,
} from './SignupPage.styled'

const SignupPage = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isCompleted, setCompleted] = useState(false)
  const [state, setState] = useState({
    username: null,
    password: null,
    passwordAgain: null,
    securityQuestion: null,
    securityAnswer: null,
  })

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    })
  }

  const handleQuestionChange = (e) => {
    setState({
      ...state,
      securityQuestion: e.target.value,
    })
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault()
    
    const [errorMsg] = await to(userService.signup(state))
    if(errorMsg) {
      return setErrorMessage(errorMsg)
    }
    setCompleted(true)
    setErrorMessage(null)
  }

  const isFormFilled = () => {
    return !!trim(state.username)
    && !!trim(state.password)
    && !!trim(state.securityQuestion)
    && !!trim(state.securityAnswer)
    && state.password === state.passwordAgain
  }

  return (
    <div>
      <Container>
        <Title>Kayit Formu</Title>
        <Form onSubmit={handleOnSubmit}>
          <FormGroup>
            <Label>Kullanici adi</Label>
            <Input
              id='username'
              onChange={handleInputChange}
              value={state.username}
              disabled={state.loading}
            />
          </FormGroup>
          <FormGroup>
            <Label>Sifre</Label>
            <Input
              id='password'
              onChange={handleInputChange}
              value={state.password}
              type='password'
              disabled={state.loading}
            />
          </FormGroup>
          <FormGroup>
            <Label>Sifre tekrar</Label>
            <Input
              id='passwordAgain'
              onChange={handleInputChange}
              value={state.passwordAgain}
              type='password'
              disabled={state.loading}
            />
          </FormGroup>
          <Divider />
          <FormGroup>
            <Label>Sifre hatirlatma sorusu</Label>
            <ForgettenPasswordQuestions
              id='securityQuestion'
              onChange={handleQuestionChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Cevap</Label>
            <Input
              id='securityAnswer'
              onChange={handleInputChange}
              value={state.answer}
              disabled={state.loading}
            />
          </FormGroup>
          {errorMessage &&
            <ErrorMessage>{errorMessage}</ErrorMessage>
          }
          {isCompleted &&
            <SuccessMessage>
              Kaydiniz gerceklesti. Lutfen uye giris ekranindan giris yapiniz
            </SuccessMessage>
          }
          {!isCompleted &&
            <ButtonWrapper>
              <Button disabled={!isFormFilled()}>Kaydol</Button>
            </ButtonWrapper>
          }
        </Form>
      </Container>
    </div>
  )
}

export default SignupPage
