import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  width: 200px;
  height: 40px;
  font-size: 16px;
  padding: 4px;
  border: 0;
  margin: 40px auto 100px;
`;