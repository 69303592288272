import to from "await-to-js";

import userService from "services/userService";

import { ACTION_TYPES } from "store/reducers/accountReducer";

import { setErrorMessage, setLoading } from "store/actions/globalActions";

export const login = (username, password) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrorMessage());

  const [err, token] = await to(userService.login(username, password));
  document.cookie = `token=${token}`;

  let bookList = [];
  const [detailError, payload] = await to(userService.getUserDetail());
  if (!detailError) {
    bookList = payload.readBookList;
  }

  dispatch(setLoading(false));
  if (err) {
    return dispatch(setErrorMessage(err));
  }

  dispatch(setAccountInfo({ username, bookList }));
};

export const getAccountDetail = () => async (dispatch) => {
  dispatch(setLoading(true));

  const [err, payload] = await to(userService.getUserDetail());
  dispatch(setLoading(false));
  if (err) {
    return;
  }
  dispatch(
    setAccountInfo({
      username: payload.username,
      bookList: payload.readBookList,
    })
  );
};

export const addReadingList = (bookDetail) => async (dispatch) => {
  dispatch(setLoading(true));

  const [err, payload] = await to(userService.addReadingList(bookDetail));
  dispatch(setLoading(false));
  if (err) {
    return dispatch(setErrorMessage(err));
  }

  dispatch({
    type: ACTION_TYPES.ADD_TO_READING_LIST,
    payload: payload.data,
  });
};

export const removeFromReadingList = (bookId) => async (dispatch) => {
  dispatch(setLoading(true));

  const [err] = await to(userService.removeFromReadingList(bookId));
  dispatch(setLoading(false));
  if (err) {
    return dispatch(setErrorMessage(err));
  }

  dispatch({
    type: ACTION_TYPES.REMOVE_FROM_READING_LIST,
    payload: bookId,
  });
};

export const setAccountInfo = (payload) => ({
  type: ACTION_TYPES.SET_ACCOUNT_INFO,
  payload,
});

export const updateLastReadPage = (id, currentPage) => async (dispatch) => {
  dispatch(setLoading(true));

  const [err] = await to(userService.updateLastReadPage(id, currentPage));
  dispatch(setLoading(false));
  if (err) {
    return dispatch(setErrorMessage(err));
  }
  dispatch({
    type: ACTION_TYPES.SET_BOOK_CURRENT_PAGE,
    payload: { id: Number(id), currentPage },
  });
};
