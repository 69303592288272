import produce from "immer";

export const ACTION_TYPES = {
  SET_ACCOUNT_INFO: "APP/SET_ACCOUNT_INFO",
  ADD_TO_READING_LIST: "APP/ADD_TO_READING_LIST",
  REMOVE_FROM_READING_LIST: "APP/REMOVE_FROM_READING_LIST",
  SET_BOOK_CURRENT_PAGE: "APP/SET_BOOK_CURRENT_PAGE",
};

export const initialState = {
  isAuthenticated: false,
  username: "",
  bookList: [],
};

const accountReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPES.SET_ACCOUNT_INFO:
        draft.isAuthenticated = true;
        draft.username = action.payload.username;
        draft.bookList = action.payload.bookList;
        break;
      case ACTION_TYPES.ADD_TO_READING_LIST:
        draft.bookList = [action.payload, ...state.bookList];
        break;
      case ACTION_TYPES.REMOVE_FROM_READING_LIST:
        draft.bookList = state.bookList.filter(
          (book) => book.bookDetail.bookId !== action.payload
        );
        break;
      case ACTION_TYPES.SET_BOOK_CURRENT_PAGE:
        draft.bookList = [
          {
            ...state.bookList.find(
              (book) => book.bookDetail.bookId === action.payload.id
            ),
            currentPage: action.payload.currentPage,
          },
          ...state.bookList.filter(
            (book) => book.bookDetail.bookId !== action.payload.id
          ),
        ];
        break;

      default:
        break;
    }
  });

export default accountReducer;
