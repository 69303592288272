import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useBreakpoint from 'use-breakpoint';

import bookService from 'services/bookService';

import {
  addReadingList,
  updateLastReadPage,
} from 'store/actions/accountActions';

import Box from 'components/Box';
import Button from 'components/Button';
import Loading from 'components/Loading';

import {
  BookDetail,
  BookPageImage,
  ButtonContainer,
  Container,
  Image,
  NotFoundContainer,
} from './BookPage.styled';
import { BREAKPOINTS } from 'theme';
import MobileView from './components/MobileView';

const BookPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const books = useSelector((s) => s.books.books);
  const { bookList, isAuthenticated } = useSelector((s) => s.account);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const isInReadingList = bookList?.some(
    (item) => Number(item?.bookDetail?.bookId) === Number(params?.bookId)
  );

  const isBookCompleted =
    isInReadingList &&
    bookList?.some((item) => {
      return (
        Number(item?.bookDetail?.bookId) === Number(params?.bookId) &&
        item.status === 'FINISHED'
      );
    });

  const [isLoading, setLoading] = useState(true);

  // const { bookId, mode = 'summary', currentPage = 1 } = useParams()
  const [mode, setMode] = useState(params?.mode || 'summary');
  const [currentPage, setCurrentPage] = useState(
    Number(params?.currentPage || 1)
  );

  const [bookDetail, setBookDetail] = useState({});
  useEffect(() => {
    const book = books.find(({ id }) => id === Number(params?.bookId));
    if (book) {
      setBookDetail(book);
      return setLoading(false);
    }
    bookService
      .getBookDetail(params?.bookId)
      .then((res) => setBookDetail(res))
      .finally(() => setLoading(false));
  }, []);

  const handleReadClick = () => {
    setMode('read');
  };

  const handleReadingListClick = () => {
    dispatch(addReadingList(bookDetail));
  };

  const handlePageChange = (pageNumber) => () => {
    setCurrentPage(pageNumber);
    if (isInReadingList && !isBookCompleted) {
      dispatch(updateLastReadPage(params?.bookId, pageNumber));
    }

    window.history.pushState(
      {},
      '',
      `/book/${params.bookId}/${pageNumber}/read`
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 37) {
      return handlePageChange(currentPage - 1)();
    } else if (e.keyCode === 39) {
      return handlePageChange(currentPage + 1)();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!bookDetail?.bookId) {
    return <NotFoundContainer>Kitap bulunamadi!</NotFoundContainer>;
  }

  if (mode === 'read' && breakpoint !== 'desktop' ) {
    return (
      <MobileView 
        currentPage={currentPage}
        bookDetail={bookDetail}
        onPageChange={handlePageChange}
      />
    )
  }

  if (mode === 'read') {
    return (
      <Box
        p={20}
        border={1}
        display="flex"
        flexDirection="column"
        tabIndex="0"
        onKeyDown={handleKeyDown}
      >
        <Box textAlign="center" mt={2} fontWeight="bold">
          {bookDetail.bookName}
        </Box>
        <ButtonContainer>
          {currentPage > 1 && (
            <Button onClick={handlePageChange(currentPage - 1)}>{`< Onceki Sayfa`}</Button>
          )}
          <Box>
            {currentPage} - {currentPage + 1} / {bookDetail.totalPageNumber}
          </Box>
          {currentPage < bookDetail.totalPageNumber && (
            <Button onClick={handlePageChange(currentPage + 1)}>{`Sonraki Sayfa >`}</Button>
          )}
        </ButtonContainer>
        <Box display="flex">
          {currentPage > 1 && (
            <BookPageImage src={`${bookDetail.url}/${currentPage}.jpg`} />
          )}
          {currentPage < bookDetail.totalPageNumber && (
            <BookPageImage src={`${bookDetail.url}/${currentPage + 1}.jpg`} />
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Container>
      <Image src={bookDetail.imageUrl} />
      <BookDetail>
        <Box fontSize={9} mb={0}>
          {bookDetail.authorName}
        </Box>
        <Box as="p">{bookDetail.bookName}</Box>
        <Box as="p" my={1}>
          {bookDetail.publisherName}
        </Box>
        <Box as="p" mb={4}>
          {bookDetail.totalPageNumber}
        </Box>
        {isAuthenticated && !isInReadingList && (
          <Button onClick={handleReadingListClick}>+ Okuma listeme ekle</Button>
        )}
        <Button onClick={handleReadClick}>Okumaya basla</Button>
      </BookDetail>
    </Container>
  );
};

export default BookPage;
