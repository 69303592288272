import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import cookie from 'js-cookie'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import HimmetPage from 'pages/HimmetPage'
import BookPage from 'pages/BookPage'
import HomePage from 'pages/HomePage'
import SigninPage from 'pages/SigninPage'
import SignupPage from 'pages/SignupPage'
import ResetPassword from 'pages/ResetPassword'
import ReadingListPage from 'pages/ReadingListPage'

import NavBar from 'components/NavBar'
import Loading from 'components/Loading'

import { getAccountDetail } from 'store/actions/accountActions'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!!cookie.get('token') && !window.location.pathname.startsWith('/himmet')) {
      dispatch(getAccountDetail())
    }
  }, [])

  const { isLoading } = useSelector(gs => gs.global)
  const store = useSelector(gs => gs)
  console.log('STORE=', store)
  return (
    <>
      <Router>
        <NavBar />
        {isLoading && <Loading />}
        <Switch>
          <Route isExact path="/himmet/:region?">
            <HimmetPage />
          </Route>
          <Route isExact path="/user">
            <ReadingListPage />
          </Route>
          <Route isExact path="/signup">
            <SignupPage />
          </Route>
          <Route isExact path="/signin">
            <SigninPage />
          </Route>
          <Route isExact path="/resetPassword">
            <ResetPassword />
          </Route>
          <Route path="/book/:bookId/:currentPage?/:mode?">
            <BookPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
