import produce from 'immer'

export const ACTION_TYPES = {
  SET_BOOKS: 'APP/SET_BOOKS',
  FETCH_MORE_BOOKS: 'APP/FETCH_MORE_BOOKS',
  SET_BOOK_CATEGORIES: 'APP/SET_BOOK_CATEGORIES',
}

export const initialState = {
  totalCount: 0,
  books: [],
  categories: [],
  searchParams: {
    searchTerm: '',
    publisherName: '',
    pageNumber: 1 ,
    pageSize: 50, 
  }
}

const bookReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case ACTION_TYPES.SET_BOOKS:
      draft.books = action.payload.books
      draft.totalCount = action.payload.totalCount
      draft.searchParams = action.payload.searchParams
      break
    case ACTION_TYPES.FETCH_MORE_BOOKS:
      draft.searchParams = action.payload.searchParams
      draft.books = [...state.books, ...action.payload.books] 
      break
    case ACTION_TYPES.SET_BOOK_CATEGORIES:
      draft.categories = action.payload
      break
    default:
      break
  }
})

export default bookReducer
