import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Input = styled.input`
  width: 400px;
  height: 40px;
  font-size: 16px;
  padding-left: 4px;
`;

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Icon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-right: 5px;
`;
export const Keyboard = styled.div`
  margin: 5px auto;
  max-width: 300px;
  curser: pointer;
  background-color: transparent;
  transform: ${(p) => (p.styledProp ? "translate(62px)" : "translate(75px)")};
`;
export const KeyboardKey = styled.button`
  margin: 0 2px;
  background-color: transparent;
  border: 0px;
  padding: 5px 8px;

  &:hover {
    color: -internal-light-dark(black, white);
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    background: #fff;
  }
`;

export const WrapperIcon = styled.button`
  display: inline-block;
  position: relative;
  background-color: white;
  left: -40px;
  border: 0px;
  curser: pointer;

  &:focus {
    outline: 0px;
  }
`;
