import axios from 'axios'

import Constants from 'config/constants'
import cookie from 'js-cookie'

const TIMEOUT = 1 * 60 * 1000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = Constants.API_BASE_URL

const setupAxiosInterceptors = () => {
  const onRequestSuccess = config => {
    const token = cookie.get('token')
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`
    }
    return config
  }
  axios.interceptors.request.use(onRequestSuccess)
}

export default setupAxiosInterceptors
