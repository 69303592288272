import styled from 'styled-components'

export const Header = styled.header`
  background-color: rgb(78, 154, 255);
  height: 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h1`
  font-size: 24px;
  color: white;
  padding: 8px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: white;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`


export const AccountItem = styled.span`
  padding: 0 8px;
  cursor: pointer;

  a {
    text-decoration: none;
    color: white;

    :hover {
      text-decoration: underline;
    }
  }
}
`

export const AccountWrapper = styled.div`
  width: 160px;
`

export const Logout = styled.button`
  border: 0;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 16px;
`