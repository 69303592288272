
import styled from 'styled-components'

const Input = styled.input`
  width: 200px;
  height: 30px;
  margin: auto;
  font-size: 16px;
  border: 1px solid #a0a4a9;
  padding: 4px 8px;
  border-radius: 2px;
`

export default Input