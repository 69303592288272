import React from "react"
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fetchBooks } from 'store/actions/bookActions'

import Box from 'components/Box'

import {
  Wrapper,
  Image,
  Body,
  Name,
  Author,
  Publisher,
} from "./BookItem.styled"


const  BookItem = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  
  // const handleAddItem = () => {
  //   props.addItemOnClick(props.bookId)
  // }

  // const handleRemoveItem = () => {
  //   props.removeItemOnClick(props.bookId)
  // }
  const handleGoToBookDetails = () => {
    history.push(`book/${props.bookId}`)
  }

  const handlePublisherClick = () => {
    if(window.location.pathname === '/') {
      window.history.pushState({ }, '', `?publisherName=${props.publisherName}`)
      dispatch(fetchBooks({ publisherName: props.publisherName }))
    }
  }

  const handleAuthorNameClick = () => {
    if(window.location.pathname === '/') {
      window.history.pushState({ }, '', `?authorName=${props.authorName}`)
      dispatch(fetchBooks({ authorName: props.authorName }))
    }
  }

  return (
    <Wrapper>
      <Image src={props.imageUrl} onClick={handleGoToBookDetails} alt="Card image cap" />
      <Body>
        <Name href={`book/${props.bookId}`}>{props.bookName}</Name>
        <Author href='#' onClick={handleAuthorNameClick}>
          {props.authorName}
        </Author>
        <Publisher href='#' onClick={handlePublisherClick}>
          {props.publisherName}
        </Publisher>
        <Box fontSize={12}>{props.totalPageNumber} sayfa</Box>
        {/* <Action className="book-item-actions">
          {props.addToList && (
            <p onClick={handleAddItem}>
              + Listeye ekle
            </p>
          )}
          {props.removeFromList && (
            <p onClick={handleRemoveItem}>
              - Listeden cikar
            </p>
          )}
        </Action> */}
      </Body>
    </Wrapper>
  )
}

export default BookItem