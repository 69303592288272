import axios from "axios";
import get from "lodash/get";

import Constants from "config/constants";

import deleteAllCookies from "utils/deleteAllCookies";

class UserService {
  constructor() {
    this.baseUrl = Constants.API_BASE_URL;
  }

  logout = () => {
    deleteAllCookies();
    window.location.href = "/";
  };

  login = (username, password) => {
    return axios
      .post(`/signin`, { username, password })
      .then((res) => res.data.token)
      .catch((err) => {
        let errorMessage = get(err, "response.data.error");
        switch (errorMessage) {
          case "InvalidUsernameOrPassword":
          case "ProvideUsernameOrPassword":
            errorMessage = "Kullanici adi veya sifre hatali!";
            break;
          default:
            errorMessage = "Bir sorun olustu!Lutfen tekrar deneyiniz!";
            break;
        }

        return Promise.reject(errorMessage);
      });
  };

  signup = async (userInfo) => {
    return axios
      .post(`/signup`, userInfo)
      .then((res) => res)
      .catch((err) => {
        let errorMessage = get(err, "response.data.error");
        switch (errorMessage) {
          case "UsernameIsInUse":
            errorMessage = "Lutfen farkli bir kullanici adi giriniz!";
            break;
          default:
            errorMessage = "Kayit gerceklesemedi!Lutfen tekrar deneyiniz!";
            break;
        }
        return Promise.reject(errorMessage);
      });
  };

  getUserDetail = () => {
    return axios.get(`/account`).then((res) => res.data);
    // .catch((err) => {
    //   let errorMessage = get(err, "response.data.error")
    //   if (errorMessage === "YouMustBeLoggedIn") {
    //     window.location.href = "/signin"
    //   }
    // })
  };

  addReadingList = (bookDetail) => {
    return axios.post(`/account/books`, bookDetail).catch((err) => {
      let errorMessage = get(err, "response.data.error");
      if (errorMessage === "YouMustBeLoggedIn") {
        window.location.href = "/login";
      }
    });
  };

  removeFromReadingList = (bookId) => {
    return axios.delete(`/account/books/${bookId}`).catch((err) => {
      let errorMessage = get(err, "response.data.error");
      if (errorMessage === "YouMustBeLoggedIn") {
        window.location.href = "/login";
      }
    });
  };

  resetPassword = (userInfo) => {
    return axios
      .post(`/resetPassword`, userInfo)
      .then((res) => res)
      .catch((err) => {
        let errorMessage = get(err, "response.data.error");
        switch (errorMessage) {
          case "UserNotFound":
            errorMessage = "Lutfen bir kullanici adi giriniz!";
            break;
          case "InvalidSecurityQuestion":
            errorMessage = "Lutfen gecerli bir guvenlik sorusu giriniz!";
            break;
          case "InvalidSecurityAnswer":
            errorMessage = "Lutfen guvenlik sorusuna dogru bir cevap giriniz!";
            break;
          default:
            errorMessage = "MissingFields!";
            break;
        }
        return Promise.reject(errorMessage);
      });
  };
  updateLastReadPage = (id, currentPage) => {
    return axios
      .put(`/account/books/${id}/currentPage/${currentPage}`)
      .then((res) => res)
      .catch((err) => {
        const errorMessage = get(err, "response.data.error");
        switch (errorMessage) {
          case "YouMustBeLoggedIn":
            return Promise.reject("Lutfen once uye girisi yapiniz!");
          case "BookNotFound":
            return Promise.reject("Boyle bir kitap sistemimizde bulunamadi!");
          default:
            return Promise.reject(
              "Bir sistem hatasi olustu sonra tekrar deneyiniz!"
            );
        }
      });
  };
}

export default new UserService();
