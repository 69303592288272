import to from 'await-to-js'

import bookService from 'services/bookService'

import { ACTION_TYPES, initialState } from 'store/reducers/bookReducer'
import { setLoading } from 'store/actions/globalActions'


export const fetchBooks = (params) => async dispatch => {
  dispatch(setLoading(true))

  const [err, payload] = await to(bookService.getBooks({
      ...params,
      pageSize: initialState.searchParams.pageSize,
      pageNumber: params?.pageNumber | initialState.searchParams.pageNumber,
    }))
  if(err) {
    return
  }
  
  dispatch(setLoading(false))

  dispatch({
    type: ACTION_TYPES.SET_BOOKS,
    payload: {
      books: payload.records,
      totalCount: payload.totalCount,
      searchParams: params,
    }
  })
}


export const fetchMoreBooks = (bookState) => async dispatch => {
  dispatch(setLoading(true))
  const params = {
    ...bookState.searchParams,
    pageSize: initialState.searchParams.pageSize,
    pageNumber: (bookState?.searchParams?.pageNumber | initialState.searchParams.pageNumber) + 1,
  }
  const [err, payload] = await to(bookService.getBooks(params))
  if(err) {
    return
  }
  
  dispatch(setLoading(false))

  dispatch({
    type: ACTION_TYPES.FETCH_MORE_BOOKS,
    payload: {
      books: payload.records,
      searchParams: params,
    }
  })
}

export const fetchBookCategories = () => async dispatch => {
  dispatch(setLoading(true))

  const [err, payload] = await to(bookService.getBookCategories())
  if(err) {
    return
  }
  
  dispatch(setLoading(false))

  dispatch({
    type: ACTION_TYPES.SET_BOOK_CATEGORIES,
    payload,
  })
}
