import Box from 'components/Box'
import { useState } from 'react'
import { Button, Input, Label } from 'reactstrap'

const Region = () => {
  const [name, setName] = useState('')
  
  const handleSave = async () => {
    window.location.href = `/himmet/${name}`
  }

  return (
    <Box margin='0 auto' mt={20} width='100%' maxWidth={400} bg='#e4f1e4' p={16}>
      <Box
        display='flex'
        justifyContent='center'
        flexDirection='column'
        p={16}
        border='1px solid #85db85'
      >
      <Label>Bolge Adi giriniz</Label>
      <Box
        as={Input}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Box
        as={Button}
        mt={20}
        color='success'
        disabled={!name.trim().length}
        onClick={handleSave}
      >
        Kaydet
      </Box>
      </Box>
    </Box>
  )
}
export default Region