import React from 'react';

import Box from 'components/Box';
import Button from 'components/Button';

import {
  ButtonContainer,
} from '../BookPage.styled';

const MobileView = (props) => {
const {currentPage, bookDetail  } = props

  const handleKeyDown = (e) => {
    if (e.keyCode === 37) {
      return props.onPageChange(currentPage - 1)();
    } else if (e.keyCode === 39) {
      return props.onPageChange(currentPage + 1)();
    }
  };

  return (
    <Box
      p={20}
      border={1}
      display="flex"
      flexDirection="column"
      tabIndex="0"
      onKeyDown={handleKeyDown}
    >
      <Box textAlign="center" mt={2} fontWeight="bold" bg='yellow'>
        {bookDetail.bookName}
      </Box>
      <ButtonContainer>
        {currentPage > 1 && (
          <Button onClick={props.onPageChange(currentPage - 1)}>{`< Onceki Sayfa`}</Button>
        )}
        <Box>
          {currentPage} / {bookDetail.totalPageNumber}
        </Box>
        {currentPage < bookDetail.totalPageNumber && (
          <Button onClick={props.onPageChange(currentPage + 1)}>{`Sonraki Sayfa >`}</Button>
        )}
      </ButtonContainer>
      <Box display="flex">
        {currentPage > 1 && (
          <Box as='img' width='96%' border='1px solid #4f9aff' borderRadius={4} src={`${bookDetail.url}/${currentPage}.jpg`} />
        )}
      </Box>
    </Box>
  )
};

export default MobileView;
