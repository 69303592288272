import styled from "styled-components";

export const Image = styled.img`
  width: 120px;
  height: 160px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 8px;
  border: 1px solid #eee;
  max-width: 400px;
  min-width: 250px;
`;

export const Body = styled.div`
  flex-grow: 2;
  margin-left: 16px;
  color: #3d3b49;
`;

export const Name = styled.a` 
  font-size: 14px;
  text-decoration: none;
`;

export const Author = styled.a`
  display: block;
  color: #8b889a;
  font-size: 12px;
  margin-top: 6px;
`;

export const Action = styled.div`
  box-sizing: border-box;
`;

export const Publisher = styled.a`
  color: #908d9e;
  font-size: 12px;
  margin: 8px 0;
`;
