import styled from 'styled-components'

export const Header = styled.header`
  background-color: rgb(78, 154, 255);
  height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  font-size: 32px;
  color: black;
  padding: 0;
`

export const Container = styled.div`
  margin: 64px auto 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 450px;
  border: 1px solid #dfe3e8;
  border-radius: 10px;
  -webkit-box-shadow: 8px 7px 24px -8px rgba(205,225,235,0.7);
  -moz-box-shadow: 8px 7px 24px -8px rgba(205,225,235,0.7);
  box-shadow: 8px 7px 24px -8px rgba(205,225,235,0.7);
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
`

export const Label = styled.label`
  font-size: 16px;
  margin-right: 16px;
  width: 150px;
  text-align: right;
`

export const Divider = styled.hr`
  border: 1px solid #96bdef;
  width: 98%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Button = styled.button`
  border: 1px solid white;
  background-color: #4d9aff;
  color: white;
  width: 100px;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`

export const ErrorMessage = styled.div`
  border: 1px solid #ff0000;
  padding: 8px;
  background-color: #ffdbdb;
  width: 370px;
  margin: 16px 0;
`

export const SuccessMessage = styled.div`
  border: 1px solid #50c829;
  padding: 8px;
  background-color: #e0fde0;
  width: 370px;
  margin: 16px 0;
`
