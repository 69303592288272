// import { createStore as reduxCreateStore } from 'redux'
import { combineReducers } from 'redux'

import account from './accountReducer'
import books from './bookReducer'
import global from './globalReducer'

export default combineReducers({
  global,
  books,
  account,
})
