import React, { useEffect, useState } from "react"
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"

import Box from "components/Box"

import Records from "./components/Records"
import Region from "./components/Region"
import Entry from "./components/Entry"

const HimmetPage = () => {
  const match = useRouteMatch()
  const region = match.params?.region
  const [records, setRecords] = useState([])

  const getRecords = () => {
    axios.get(`/himmets/${region}`).then(response => setRecords(response.data))
  }

  useEffect(() => {
    if (!region) {
      return
    }
    getRecords()
    const intervalId = setInterval(() => {
      getRecords()
    }, 20000)

    return () => clearInterval(intervalId)
  }, [])

  if (!region) {
    return <Region />
  }

  return (
    <Box margin='0 auto' width='100%' height='100%' bg='#e4f1e4' p={16}>
      <Box margin='0 auto' fontSize={24} fontWeight='bold' textAlign='center'>{region.toUpperCase()}</Box>
      <Entry region={region} onRefresh={() => getRecords()} />
      <Records records={records} onRefresh={() => getRecords()} />
    </Box>
  )
}

export default HimmetPage
