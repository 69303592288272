import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Progress } from 'reactstrap'

import { removeFromReadingList } from 'store/actions/accountActions'

import Box from 'components/Box'
import { Button } from 'reactstrap'

import {
  BookContainer,
  Image,
  Wrapper
} from './ReadingListPage.styled'

const ReadingListPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const account = useSelector(s => s.account)
  const [status, setStatus] = useState('INPROGRESS')
  
  useEffect(() => {
    if(!account.isAuthenticated) {
      history.push('/signin')
    }
  }, [])
  // const handlePageChange = (pageNumber) => () => {
  //   setCurrentPage(pageNumber)
  //   window.history.pushState({}, "", `/book/${params.bookId}/${pageNumber}/read`);
  // }
  const handleReadClick = (bookId, currentPage) => () => {
    history.push(`book/${bookId}/${currentPage}/read`)
  }

  const handleRemoveClick = (bookId) => () => {
    dispatch(removeFromReadingList(bookId))
  }

  const handleCategoryClick = (newStatus) => () => {
    setStatus(newStatus)
  }

  if(!account?.bookList?.length) {
    return (
      <Wrapper>
        Okuma listenizde herhangi bir kayit bulunamadi
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Box fontSize={32} mb={8}>Kitap Listem</Box>
      <Box display='flex' fontSize={16} my={3}>
        <Box
          cursor={status === 'FINISHED' && 'pointer'}
          onClick={handleCategoryClick('INPROGRESS')}
          color={status === 'FINISHED' && 'blue'}
        >
          Devam Ettiklerim
        </Box>
        <Box mx={2}>|</Box>
        <Box
          cursor={status !== 'FINISHED' && 'pointer'}
          color={status !== 'FINISHED' && 'blue'}
          onClick={handleCategoryClick('FINISHED')}
        >
          Bitenler
        </Box>
      </Box>
      <Box as='hr' borderColor='blue' width='100%'/>
      {account.bookList.filter(book => book.status === status).map(book => (
        <BookContainer>
          <Image src={book.bookDetail.imageUrl} />
          <Box>
            <Box color='blue' fontWeight='bold'>{book.bookDetail.bookName}</Box>
            <Box>
              {book.bookDetail.authorName}
            </Box>
            <Box>{book.bookDetail.publisherName}</Box>
            <Box>{book.bookDetail.totalPageNumber} sayfa</Box>
            <Box>En son okunan sayfa: {book.currentPage}</Box>
            {book.status === 'INPROGRESS' &&
              <>
                <Progress multi>
                  <Progress bar color="success" value={book.currentPage}>
                  {book.currentPage}
                  </Progress>
                  <Progress bar color="warning" value={book.bookDetail.totalPageNumber - book.currentPage}>
                    {book.bookDetail.totalPageNumber - book.currentPage}
                  </Progress>
                </Progress>
                <Box
                  as={Button}
                  color="primary"
                  display='block'
                  my={5}
                  size="sm"
                  onClick={handleReadClick(book.bookDetail.bookId, book.currentPage)}
                >
                  Okumaya devam et
                </Box>
                <Button size="sm" color='danger' onClick={handleRemoveClick(book.bookDetail.bookId)}>
                  Listeden Cikar
                </Button>
              </>
            }
          </Box>
        </BookContainer>
      ))}
    </Wrapper>
  )
}

export default ReadingListPage
