import axios from 'axios'
import { Button } from 'reactstrap'

import Box from 'components/Box'

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

const Records = ({records = [], onRefresh}) => {

  const handleDelete = (item) => () => {
    axios.delete(`/himmets/${item.region}/${item.name}`).then(() => {
      onRefresh()
    })
  }
  
  const totalAmount = records?.reduce((acc, cur) => acc + cur.amount, 0)

  return (
    <Box display='flex' alignItems='center' flexDirection='column' >
      <Box as='hr' border='1px solid #28a745' width='100%' />
      <Box mt={3} fontSize={30} fontWeight='bold' textAlign='center' mb={2}>Toplam: {formatter.format(totalAmount)}</Box>
      <Box fontSize={20} fontWeight='bold' textAlign='center' mb={20}>Kisi sayisi: {records.length}</Box>
      <Box as='hr' border='1px solid #28a745' width='100%' />
      <Box mb={6}>
        <Box as={Button} color="primary" onClick={() => onRefresh()}>Yenile</Box>  
      </Box>
      <Box border='1px solid #e4f1e4' mb={2}>
      {records.map((item, i) => (
        <Box
          width='100%'
          minWidth={320}
          fontSize={20}
          display='flex'
          flexDirection='row'
          bg={i%2===0 ? '#b6e184' : '#9cd25e'}
          px={10}
        >
          <Box width='60%'>{item.name}</Box>
          <Box width='35%' textAlign='end'>
            {item.amount?.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
          </Box>
          <Box
            width='5%'
            ml={40}
            textAlign='center'
            fontSize={14}
            fontWeight='bold'
            color='red'
            cursor='pointer'
            onClick={handleDelete(item)}
          >X</Box>
        </Box>
      ))}
      </Box>
    </Box>
  )
}
export default Records