const colors = {
  white: "#ffffff",
  lightYellow: "#fffbbe",
  yellow: "#ffde14",
  deepNavy: "#293640",
  contrastYellow: "#feda00",
  powderBlue: "#476eeb",
  alert: "#f5cccc",
  link: "#3399F3",
  blue: "#4d9aff",
  red: "#e12424",
  border: "#e5e5e5",
  warningRed: "#f76b6b",
  lightGreen: "#f0feec",
  successGreen: "#38ac1f",
  tinGrey: "#f5f4f4",
  lightGrey: "#f1f1f1",
  midLightGrey: "#d6d6d6",
  midGrey: "#e6e2e2", // '#acacac',
  midDarkGrey: "#595959",
  darkGrey: "#303030",
  softBlue: "#a1c1ea",
  transparent: "transparent",
};

export const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1024 }

const theme = {
  colors,
  backgroundColor: { ...colors },
  space: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30], // 16px, 24px, 32px, 48px, 64px, 96px, 160px
  breakpoints: [320, 768, 1024],
  fontSizes: [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },
  borders: [
    0,
    "1px solid",
    "2px solid",
    "4px solid",
    "8px solid",
    "16px solid",
    "32px solid",
  ],
  shadows: [0, 2, 4, 10, 16, 9999, "100%"], // border-radius
  radii: [0, 2, 4, 8, 10, 16, 9999, "100%"], // border-radius
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
};

export default theme;
