import { useDispatch, useSelector } from 'react-redux'

import { fetchMoreBooks } from 'store/actions/bookActions'

import { Button, Wrapper } from "./LoadMoreBooks.styled";

const LoadMoreBooks = () => {
  const dispatch = useDispatch()
  const bookState = useSelector(gs => gs.books)

    const handleClick = () => {
      dispatch(fetchMoreBooks(bookState))
    }

  if(bookState.totalCount <= bookState.books.length) {
    return null
  }

  return (
    <Wrapper>
      <Button onClick={handleClick}>
        Daha fazla goruntule
      </Button>
    </Wrapper>
  );
};
export default LoadMoreBooks;
