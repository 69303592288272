import styled from "styled-components";

const Button = styled.button`
  border: 1px solid white;
  background-color: ${(p) =>
    p.disabled
      ? p.theme.backgroundColor.midGrey
      : p.theme.backgroundColor.powderBlue};
  color: white;
  width: max-content;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

export default Button;
