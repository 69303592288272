import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchBooks } from "store/actions/bookActions"

import BookList from "components/BookList"
import Search from "components/Search"
import { useHistory } from 'react-router-dom'

const HomePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const searchParams = useSelector((gs) => gs.books.searchParams)

  useEffect(() => {
    const params = { }
    if(history.location.search) {
      history.location.search
        .replace('?', '')
        .split('&')
        .forEach(item => {
          if(item.includes('=')) {
            const [propName, value] = item.split('=')
            params[propName] = value
          }
        })
    }
    dispatch(fetchBooks(params))
  }, [])

  const [searchTerm, setSearchTerm] = useState(searchParams?.searchTerm || "")

  const handleInputOnChange = (e) => {
    setSearchTerm(e.target.value)
  }
  const handleOnSubmit = () => {
    if (
      !searchTerm ||
      searchTerm === "" ||
      searchTerm.length < 2 ||
      searchParams?.searchTerm === searchTerm
    ) {
      return
    }
    dispatch(fetchBooks({ searchTerm }))
  }

  return (
    <div>
      <Search
        onSubmit={handleOnSubmit}
        onChange={handleInputOnChange}
        value={searchTerm}
      />
      <BookList />
    </div>
  )
}

export default HomePage
